import React, { useEffect, useState, useContext, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import {
  RootGetterContext,
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import {
  getMenu,
  updateMenu,
  publishMenu,
  getBuildMenuStatus,
  getIndicator,
  renderTooltip,
  importCsvMenu,
} from '../helpers'
import { ntfError, ntfSuccess, ntfInfo, ntfWarn, ntfErrorWithLink } from '../helpers/notifications'
import { useIsDesktop } from '../helpers/hooks'
import { de } from 'date-fns/locale'

const { REACT_APP_BASE_URL } = process.env

const ExportBlock = () => {
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { token, isLoadingGlobal } = useContext(RootGetterContext)
  const {
    menuId,
    alias,
    isThereUnsavedChanges,
    defaultLanguage,
    currentLanguage,
    events,
    isDeleted,
    logo,
    mainBgColor,
    mainTextColor,
    splashBgColor,
    splashTextColor,
    socials,
    templateName,
  } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges, setEvents, setIsPreviewOpen } =
    useContext(ConstructorSetterContext)
  const { user, isAuthenticated } = useAuth0()
  const { t, i18n } = useTranslation()
  const isDesktop = useIsDesktop()

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [isImporting, setIsImporting] = useState(false)
  const [importInputKey, setImportInputKey] = useState(Math.random().toString(36))
  const [statusData, setStatusData] = useState(null)

  let timeout = null

  const autoUpdate = async () => {
    timeout && clearTimeout(timeout)
    if (getBuildMenuStatus(events) === 1) {
      setIsPublishing(true)
      setIsImporting(true)
      const menuData = await getMenu(token, user.sub, menuId)
      if (menuData.success && menuData.data && menuData.data.events) {
        const updatedEvents = menuData.data.events
        setStatusData(getIndicator(updatedEvents, i18n.language, t))
        const status = getBuildMenuStatus(updatedEvents)
        switch (status) {
          case 0: {
            setIsPublishing(false)
            setIsImporting(false)
            ntfInfo({ message: t('MESSAGE_MENU_PUBLISH_SUCCESS') })
            break
          }
          case 2: {
            setIsPublishing(false)
            setIsImporting(false)
            ntfError({ message: t('MESSAGE_MENU_PUBLISH_ERROR') })
            break
          }
          default:
            break
        }
        timeout = setTimeout(async () => {
          setEvents(updatedEvents)
        }, process.env.REACT_APP_CONSTRUCTOR_INDICATOR_REFRESH_RATE)
      } else {
        ntfErrorWithLink({
          title: t('MESSAGE_ERROR_TITLE'),
          link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
        })
        timeout = setTimeout(async () => {
          autoUpdate()
        }, process.env.REACT_APP_CONSTRUCTOR_INDICATOR_REFRESH_RATE)
      }
    } else {
      setIsPublishing(false)
      setIsImporting(false)
      setStatusData(getIndicator(events, i18n.language, t))
      timeout = setTimeout(async () => {
        autoUpdate()
      }, process.env.REACT_APP_CONSTRUCTOR_INDICATOR_REFRESH_RATE)
    }
  }

  useEffect(() => {
    autoUpdate()
    return () => timeout && clearTimeout(timeout)
  }, [events, i18n.language])

  useEffect(() => {
    setIsPreviewOpen(isDesktop)
  }, [isDesktop, setIsPreviewOpen])

  useEffect(() => {
    setIsDataLoaded(
      menuLocals &&
        logo !== null &&
        mainBgColor &&
        mainTextColor &&
        splashBgColor &&
        splashTextColor &&
        socials
    )
  }, [menuLocals, logo, mainBgColor, mainTextColor, splashBgColor, splashTextColor, socials])

  const handlePreview = () => {
    setIsPreviewOpen((state) => !state)
  }

  const handleSave = async (forceMenuLocals = null) => {
    if (isLoadingGlobal || isSaving) return false
    if (!menuLocals && !isThereUnsavedChanges) return true
    setIsSaving(true)
    try {
      const menuToSave = {
        id: menuId,
        alias,
        categoriesList: 'main',
        defaultLanguage,
        events,
        isDeleted,
        logo: logo ? logo : '', // fix: replace null/undefined with empty string
        mainBgColor,
        mainTextColor,
        splashBgColor,
        splashTextColor,
        socials,
        templateName,
        content: forceMenuLocals || menuLocals,
        url: '#',
      }

      const savedMenu = await updateMenu(token, user.sub, menuToSave)
      if (savedMenu.success) {
        setEvents(savedMenu.data)
        setIsThereUnsavedChanges(false)
        ntfSuccess({ message: t('MESSAGE_MENU_SAVE_SUCCESS') })
        return true
      } else {
        ntfError({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
        console.error('save menu error:\n', savedMenu.error)
        return false
      }
    } catch (e) {
      ntfErrorWithLink({
        title: 'save menu error',
        message: e.message,
        link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
      })
      console.error('save menu error:\n', e.message)
      return false
    } finally {
      setIsSaving(false)
    }
  }

  const handlePublish = async (e) => {
    if (!handleSave()) return false

    if (isLoadingGlobal || isSaving) return false
    try {
      const publishedMenu = await publishMenu(token, user.sub, menuId, alias, templateName)
      if (publishedMenu.success) {
        setIsPublishing(true)
        setIsImporting(true)
        ntfInfo({ message: t('MESSAGE_MENU_PUBLISH_STARTED') })
        events.buildedAt = new Date().toISOString() //needed to start the process of checking the build status
        return true
      } else {
        if (publishedMenu.error === 2) {
          ntfWarn({ message: t('MESSAGE_MENU_PUBLISH_IN_PROGRESS') })
        } else {
          ntfError({ message: t('MESSAGE_MENU_PUBLISH_ERROR') })
        }
        return false
      }
    } catch (e) {
      setIsPublishing(false)
      setIsImporting(false)
      ntfErrorWithLink({
        title: 'publish menu error',
        message: e.message,
        link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
      })
      console.error('publish menu error:\n', e.message)
      return false
    }
  }

  const handleImportCSV = async (e) => {
    e.preventDefault()
    if (isLoadingGlobal || isSaving || isPublishing || isImporting) return false
    try {
      setIsImporting(true)
      const file = e.target.files[0]

      if (!file) {
        return false
      }

      if (file.type !== 'text/csv') {
        ntfWarn({ message: 'Only CSV files are supported' })
        return false
      }

      if (
        confirm(
          'Importing CSV menu will overwrite your current menu. Do you want to continue? THERE IS NO WAY TO RECOVER.'
        ) !== true
      ) {
        return false
      }

      const importedMenu = await importCsvMenu(menuLocals, file)
      console.log({ importedMenu, menuLocals })

      if (importedMenu.success) {
        console.log('importedMenu.data', importedMenu.data)
        setMenuLocals(importedMenu.data)
        ntfSuccess({ message: 'Menu is successfully imported' })
        setIsThereUnsavedChanges(true)
        // alert(JSON.stringify({isLoadingGlobal, isSaving, isPublishing, isImporting, isThereUnsavedChanges}))
        const saved = await handleSave(importedMenu.data)
        if (saved) {
          window.location.reload()
          return true
        } else {
          ntfError({
            title: 'Import CSV Error',
            message: 'Something went wrong. Please try again!',
          })
          resetImportInput()
          return false
        }
      } else {
        ntfError({ title: 'Import CSV Error', message: 'Something went wrong. Please try again!' })
        resetImportInput()
        console.error('import menu error:\n', importedMenu.error)
        return false
      }
    } catch (e) {
      ntfErrorWithLink({
        title: 'publish menu error',
        message: e.message,
        link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
      })
      console.error('import csv menu error:\n', e.message)
      resetImportInput()
      return false
    } finally {
      setIsImporting(false)
    }
  }

  const resetImportInput = () => {
    setImportInputKey(Math.random().toString(36))
  }

  const isUserCanUseSummary = useMemo(() => {
    if (user && isAuthenticated) {
      const userRolesArray = user[`${REACT_APP_BASE_URL}/roles`]
      return userRolesArray?.includes('admin')
    } else return false
  }, [user, isAuthenticated])

  return (
    <>
      <div id='export-import' className='export-import'>
        {!isDesktop && (
          <div className='item preview'>
            {isDataLoaded ? (
              <div
                className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
                onClick={handlePreview}
              >
                {t('CON_BUTTON_PREVIEW')}
              </div>
            ) : (
              <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
                <Skeleton />
              </div>
            )}
          </div>
        )}
        <div className='item save'>
          {isDataLoaded ? (
            <div
              className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
              onClick={(e) => handleSave()}
            >
              {isSaving && (
                <Spinner as='span' role='status' animation='border' variant='danger' size='sm' />
              )}{' '}
              {t('CON_BUTTON_SAVE')}
            </div>
          ) : (
            <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
              <Skeleton />
            </div>
          )}
        </div>
        {isUserCanUseSummary && currentLanguage === defaultLanguage && (
          <div>
            {isDataLoaded ? (
              <label className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline block`}>
                <input
                  key={importInputKey}
                  type='file'
                  className='block w-full text-sm text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
            '
                  onChange={isImporting ? null : handleImportCSV}
                />
                {isImporting ? (
                  <Spinner as='span' role='status' animation='border' variant='danger' size='sm' />
                ) : (
                  <span className='block'>Import CSV</span>
                )}
              </label>
            ) : (
              <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
                <Skeleton />
              </div>
            )}
          </div>
        )}
        <div className='item publish'>
          {isDataLoaded ? (
            <>
              {isDesktop ? (
                <OverlayTrigger
                  placement='bottom-end'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    statusData && (
                      <div className='status'>
                        {statusData.map((item, i) => (
                          <p key={i}>{item}</p>
                        ))}
                      </div>
                    )
                  )}
                >
                  <div
                    className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
                    onClick={(e) => handlePublish(e)}
                  >
                    {isPublishing && (
                      <Spinner
                        as='span'
                        role='status'
                        animation='border'
                        variant='danger'
                        size='sm'
                      />
                    )}{' '}
                    {t('CON_BUTTON_PUBLISH')}
                  </div>
                </OverlayTrigger>
              ) : (
                <>
                  <div
                    className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
                    onClick={(e) => handlePublish(e)}
                  >
                    {isPublishing && (
                      <Spinner animation='border' variant='outline-info' size='sm' />
                    )}{' '}
                    {t('CON_BUTTON_PUBLISH')}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
              <Skeleton />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ExportBlock

import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ConstructorGetterContext } from '../context/userContext'
import IndicatorPanel from './IndicatorPanel'
import ColorPickers from './ColorPickers'
import LogoLoader from './LogoLoader'
import MeasureUnits from './MeasureUnits'
import Socials from './Socials'
import LanguageChoose from './LanguageChoose'
import RestaurantName from './RestaurantName'
import ContentBlock from './ContentBlock'
import TemplateChooser from './TemplateChooser'
const MenuItemConstructor = () => {
  const { menuId } = useContext(ConstructorGetterContext)
  const { t } = useTranslation()

  return (
    <Container id={menuId} className='constructor' fluid>
      <IndicatorPanel />

      <div id='beauty-container' className='beauty-container'>
        {menuId ? (
          <h5>{t('CON_COLORS_TEXT')}</h5>
        ) : (
          <h5>
            <Skeleton />
          </h5>
        )}
        <ColorPickers />
        <LogoLoader />
        <Socials />
      </div>

      <LanguageChoose />

      <div id='content-container' className='content-container top-line'>
        <Row className='measure-title-container'>
          <Col className='measure-title' xl>
            {menuId ? (
              <h5>{t('CON_MEASURE_TEXT')}</h5>
            ) : (
              <h5>
                <Skeleton />
              </h5>
            )}
          </Col>
        </Row>
        <Row className='measure-units'>
          <MeasureUnits />
        </Row>
      </div>

      <TemplateChooser />

      <RestaurantName />

      <ContentBlock />
    </Container>
  )
}

export default MenuItemConstructor

import React, { useEffect, useContext, useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDebouncedCallback } from 'use-debounce'
import { useAuth0 } from '@auth0/auth0-react'
import { ConstructorGetterContext, ConstructorSetterContext } from '../context/userContext'
const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const AVAILABLE_TEMPLATES = [
  {
    title: 'Default',
    templateName: 'default',
  },
  {
    title: 'Premium',
    templateName: 'premium',
  },
]

const TemplateChooser = () => {
  const { user, isAuthenticated } = useAuth0()
  const { templateName } = useContext(ConstructorGetterContext)
  const { setTemplateName, setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)

  const handleTemplateChange = (templateName) => {
    setTemplateName(templateName)
    setIsThereUnsavedChanges(true)
  }

  console.log('templateName', { templateName })

  const isAdmin = useMemo(() => {
    if (user && isAuthenticated) {
      const userRolesArray = user[`${process.env.REACT_APP_BASE_URL}/roles`]
      return userRolesArray?.includes('admin')
    } else return false
  }, [user, isAuthenticated])

  return isAdmin ? (
    <Row className='rest-name-container top-line'>
      <Col id='rest-name' className='rest-name' xl>
        {templateName ? (
          <div className='flex items-center gap-2'>
            <div>Select Template [EXPERIMENTAL]:</div>
            <select
              className='px-3 py-2 border border-gray-600 hover:border-gray-700 rounded-md'
              value={templateName}
              onChange={(e) => handleTemplateChange(e.target.value)}
            >
              {AVAILABLE_TEMPLATES.map((template) => (
                <option key={template.templateName} value={template.templateName}>
                  {template.title}
                </option>
              ))}
            </select>
          </div>
        ) : null}
      </Col>
    </Row>
  ) : null
}

export default TemplateChooser

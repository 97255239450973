import React, { useState } from 'react'

export const RootGetterContext = React.createContext()
export const RootSetterContext = React.createContext()

export const RootContextProvider = ({ children }) => {
  const [token, setToken] = useState(null)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false)

  return (
    <RootGetterContext.Provider
      value={{
        token,
        showRemoveModal,
        isLoadingGlobal,
      }}
    >
      <RootSetterContext.Provider
        value={{
          setToken,
          setShowRemoveModal,
          setIsLoadingGlobal,
        }}
      >
        {children}
      </RootSetterContext.Provider>
    </RootGetterContext.Provider>
  )
}

export const ManagerGetterContext = React.createContext()
export const ManagerSetterContext = React.createContext()

export const ManagerContextProvider = ({ children }) => {
  const [userObject, setUserObject] = useState(null)
  const [showAliasModal, setShowAliasModal] = useState(false)
  const [showAliasOnlyModal, setShowAliasOnlyModal] = useState(false)
  const [qrPopup, setQrPopup] = useState(false)

  return (
    <ManagerGetterContext.Provider
      value={{
        userObject,
        showAliasModal,
        showAliasOnlyModal,
        qrPopup,
      }}
    >
      <ManagerSetterContext.Provider
        value={{
          setUserObject,
          setShowAliasModal,
          setShowAliasOnlyModal,
          setQrPopup,
        }}
      >
        {children}
      </ManagerSetterContext.Provider>
    </ManagerGetterContext.Provider>
  )
}

export const ConstructorGetterContext = React.createContext()
export const ConstructorSetterContext = React.createContext()

export const ConstructorContextProvider = ({ children }) => {
  const [menuId, setMenuId] = useState(null)
  const [alias, setAlias] = useState(null)
  const [isThereUnsavedChanges, setIsThereUnsavedChanges] = useState(false)
  const [defaultLanguage, setDefaultLanguage] = useState(null)
  const [currentLanguage, setCurrentLanguage] = useState(null)
  const [events, setEvents] = useState(null)
  const [isDeleted, setIsDeleted] = useState(null)
  const [logo, setLogo] = useState(null)
  const [mainBgColor, setMainBgColor] = useState(null)
  const [mainTextColor, setMainTextColor] = useState(null)
  const [splashBgColor, setSplashBgColor] = useState(null)
  const [splashTextColor, setSplashTextColor] = useState(null)
  const [socials, setSocials] = useState(null)
  const [url, setUrl] = useState(null)
  const [showList, setShowList] = useState([])
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [startTour, setStartTour] = useState(false)
  const [templateName, setTemplateName] = useState('default')

  return (
    <ConstructorGetterContext.Provider
      value={{
        menuId,
        alias,
        isThereUnsavedChanges,
        defaultLanguage,
        currentLanguage,
        events,
        isDeleted,
        logo,
        mainBgColor,
        mainTextColor,
        splashBgColor,
        splashTextColor,
        socials,
        url,
        showList,
        isPreviewOpen,
        startTour,
        templateName,
      }}
    >
      <ConstructorSetterContext.Provider
        value={{
          setMenuId,
          setAlias,
          setIsThereUnsavedChanges,
          setDefaultLanguage,
          setCurrentLanguage,
          setEvents,
          setIsDeleted,
          setLogo,
          setMainBgColor,
          setMainTextColor,
          setSplashBgColor,
          setSplashTextColor,
          setSocials,
          setUrl,
          setShowList,
          setIsPreviewOpen,
          setStartTour,
          setTemplateName,
        }}
      >
        {children}
      </ConstructorSetterContext.Provider>
    </ConstructorGetterContext.Provider>
  )
}

export const MenuLocalsGetterContext = React.createContext()
export const MenuLocalsSetterContext = React.createContext()

export const MenuLocalsContextProvider = ({ children }) => {
  const [menuLocals, setMenuLocals] = useState([])
  const [isLocalsLoading, setIsLocalsLoading] = useState(false)
  const [prevItemProps, setPrevItemProps] = useState({
    weight: '100',
    measure: 'm',
    price: '99',
    sku: '',
    image: '',
  })

  return (
    <MenuLocalsSetterContext.Provider
      value={{
        setMenuLocals,
        setPrevItemProps,
        setIsLocalsLoading,
      }}
    >
      <MenuLocalsGetterContext.Provider
        value={{
          menuLocals,
          prevItemProps,
          isLocalsLoading,
        }}
      >
        {children}
      </MenuLocalsGetterContext.Provider>
    </MenuLocalsSetterContext.Provider>
  )
}

export const UserContext = React.createContext()
